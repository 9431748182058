<template>
  <layout>
    <template v-slot:content>
      <div class="md:w-3/4 mx-auto">
        <FullCalendar
          ref="fullCalendar"
          id="calendar"
          :options="{ ...calendarOptions, events: agendas }"
        />
      </div>
      <t-modal v-model="eventModal" header="Event" @closed="clear">
        <div class="mb-3 px-2">
          <label>Title</label>
          <t-input
            class="w-full"
            v-model="form.title"
            :variant="form.error.title ? 'danger' : ''"
          />
        </div>
        <div class="flex mb-3 flex mb-3">
          <div class="flex px-2">
            <label class="mr-3">Recurring</label>
            <t-toggle v-model="form.isRecurring" />
          </div>
          <div v-if="!form.isRecurring" class="flex px-2">
            <label class="mr-3">All day</label>
            <t-toggle v-model="form.allDay" :disabled="hasRecurringHour" />
          </div>
        </div>
        <div v-if="!form.isRecurring" class="flex flex-nowrap mb-3">
          <div class="px-2">
            <label>Start date</label>
            <t-datepicker
              v-model="form.start"
              user-format="M d, Y H:i"
              date-format="M d, Y H:i"
              :variant="form.error.start ? 'danger' : ''"
              timepicker
            />
          </div>
          <div class="px-2">
            <label>End date</label>
            <t-datepicker
              v-model="form.end"
              user-format="M d, Y H:i"
              date-format="M d, Y H:i"
              :variant="form.error.end ? 'danger' : ''"
              timepicker
            />
          </div>
        </div>
        <template v-else>
          <div class="flex flex-nowrap mb-3">
            <div class="px-2">
              <label>Start date</label>
              <t-datepicker
                v-model="form.startRecur"
                :variant="form.error.startRecur ? 'danger' : ''"
              />
            </div>
            <div class="px-2">
              <label>End date</label>
              <t-datepicker
                v-model="form.endRecur"
                :variant="form.error.endRecur ? 'danger' : ''"
              />
            </div>
          </div>
          <div class="flex flex-nowrap mb-3">
            <div class="px-2">
              <label>Start time</label>
              <t-datepicker
                v-model="form.startTime"
                user-format="H:i"
                date-format="H:i"
                :datepicker="false"
                :variant="form.error.startTime ? 'danger' : ''"
                timepicker
              />
            </div>
            <div class="px-2">
              <label>End time</label>
              <t-datepicker
                v-model="form.endTime"
                user-format="H:i"
                date-format="H:i"
                :datepicker="false"
                :variant="form.error.endTime ? 'danger' : ''"
                timepicker
              />
            </div>
          </div>
          <div class="mb-3 px-2">
            <label class="flex items-center">
              <span class="mr-2">Days of Week</span>
              <span class="text-sm leading-tight text-gray-600">
                (Leave blank for all days)
              </span>
            </label>
            <t-rich-select
              v-model="form.daysOfWeek"
              :options="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ]"
              :close-on-select="false"
              :hideSearchBox="true"
              placeholder="Select days of recurrence"
              multiple
            />
          </div>
          <div class="mb-3 px-2">
            <label>Group Id</label>
            <t-input class="w-full" v-model="form.groupId" />
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              v-if="editing"
              class="text-sm rounded-md mr-2"
              variant="danger"
              :disabled="loading"
              @click="remove"
            >
              Delete
            </t-button>
            <t-button
              class="text-sm"
              variant="primary"
              :disabled="loading"
              @click="editing ? update() : create()"
            >
              {{ editing ? "Update" : "Save" }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <t-dialog icon="question" type="confirm"></t-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { weekDays } from "@/constants/misc";
import { get, map, pick } from "lodash";
import moment from "@/utils/moment-utc";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

export default {
  components: { Layout, FullCalendar },
  data() {
    return {
      form: {
        title: null,
        start: null,
        end: null,
        allDay: false,
        startRecur: null,
        endRecur: null,
        startTime: null,
        endTime: null,
        groupId: null,
        daysOfWeek: [],
        isRecurring: false,
        horeca_location_ID: null,
        error: {
          title: false,
          start: false,
          end: false,
          startRecur: false,
          endRecur: false,
          startTime: false,
          endTime: false,
          groupId: false,
        },
      },
      selected: {
        start: null,
        end: null,
        startTime: null,
        endTime: null,
        daysOfWeek: [],
      },
      calendarOptions: {
        initialView: "dayGridMonth",
        plugins: [dayGridPlugin, interactionPlugin],
        firstDay: 1,
        editable: true,
        selectable: true,
        displayEventTime: false,
        dayMaxEvents: true,
        select: this.dateSelect,
        eventClick: this.openEvent,
      },
      editing: false,
      loading: false,
      eventModal: false,
    };
  },
  methods: {
    dateSelect({ start, end }) {
      this.selected = { ...this.selected, start, end };
      this.form.start = start;
      this.form.end = end;
      this.eventModal = true;
    },
    openEvent({ event }) {
      this.editing = true;

      if (event.groupId) {
        const recurring = get(event, "_def.recurringDef.typeData");
        const daysOfWeek = map(recurring.daysOfWeek, (k) => weekDays.B[k]);
        const times = {
          startTime: recurring.startTime ? this.hourFormat(event.start) : null,
          endTime: this.hourFormat(event.end),
        };
        this.form = {
          ...this.form,
          ...pick(event, ["id", "allDay", "title", "groupId"]),
          ...pick(recurring, ["startRecur", "endRecur"]),
          ...times,
          isRecurring: true,
          daysOfWeek,
        };
        this.selected = {
          start: recurring.startRecur,
          end: recurring.endRecur,
          ...times,
          daysOfWeek,
        };
      } else {
        this.form = {
          ...this.form,
          ...pick(event, ["id", "allDay", "title", "start", "end"]),
        };
        this.selected = {
          ...this.selected,
          ...pick(event, ["start", "end"]),
        };
      }

      this.eventModal = true;
    },
    async create() {
      if (!this.validated()) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          actions.AGENDAS_CREATE_ACTION,
          this.getFormValues()
        );
        this.eventModal = false;
      } catch (error) {
        console.error(actions.AGENDAS_CREATE_ACTION, error);
      }

      this.loading = false;
    },
    async update() {
      if (!this.validated()) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(actions.AGENDAS_UPDATE_ACTION, {
          id: this.form.id,
          payload: this.getFormValues(),
        });
        this.eventModal = false;
      } catch (error) {
        console.error(actions.AGENDAS_UPDATE_ACTION, error);
      }

      this.loading = false;
    },
    async remove() {
      const { id, title } = this.form;
      const { isOk } = await this.$dialog.confirm(
        "Delete event",
        `Are you sure to delete ${title} event?`,
        "info"
      );

      if (!isOk) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(actions.AGENDAS_REMOVE_ACTION, id);
        this.eventModal = false;
      } catch (error) {
        console.error(actions.AGENDAS_REMOVE_ACTION, error);
      }

      this.loading = false;
    },
    validated() {
      this.form.error.title = !this.form.title;

      if (this.form.isRecurring) {
        this.form.error.startRecur = !this.form.startRecur;
        this.form.error.endRecur = !this.form.endRecur;
        this.form.error.startTime = !this.form.startTime && this.form.endTime;
        this.form.error.endTime = this.form.startTime && !this.form.endTime;
        this.form.error.groupId = !this.form.groupId;
        this.form.error.start = false;
        this.form.error.end = false;

        if (!this.form.error.startRecur && !this.form.error.endRecur) {
          const invalid = !moment(
            `${this.form.endRecur} ${this.form.endTime || "00:00"}`
          ).isSameOrAfter(
            moment(`${this.form.startRecur} ${this.form.startTime || "00:00"}`)
          );
          this.form.error.startRecur = invalid;
          this.form.error.endRecur = invalid;
        }
      } else {
        this.form.error.startRecur = false;
        this.form.error.endRecur = false;
        this.form.error.groupId = false;
        this.form.error.start = !this.form.start;
        this.form.error.end = !this.form.end;

        if (!this.form.error.start && !this.form.error.end) {
          const invalid = !moment(this.form.end).isSameOrAfter(
            moment(this.form.start)
          );
          this.form.error.start = invalid;
          this.form.error.end = invalid;
        }
      }

      return Object.keys(this.form.error).every((k) => !this.form.error[k]);
    },
    getFormValues() {
      return {
        title: this.form.title,
        horeca_location_ID: this.domainLocationId,
        all_day: !this.hasRecurringHour ? this.form.allDay : false,
        start: !this.form.isRecurring
          ? this.datetimeFormat(this.form.start)
          : null,
        end: !this.form.isRecurring ? this.datetimeFormat(this.form.end) : null,
        start_recur: this.form.isRecurring
          ? this.dateFormat(this.form.startRecur)
          : null,
        end_recur: this.form.isRecurring
          ? this.dateFormat(this.form.endRecur)
          : null,
        start_time: this.form.isRecurring ? this.form.startTime : null,
        end_time: this.form.isRecurring ? this.form.endTime : null,
        group_id: this.form.groupId,
        days_of_week: this.form.daysOfWeek.length
          ? Object.values(this.form.daysOfWeek).map((k) => weekDays.A[k])
          : null,
      };
    },
    clear() {
      Object.keys(this.form).map((k) => {
        if (k === "error") {
          Object.keys(this.form.error).map((k) => (this.form.error[k] = false));
          return;
        }

        if (k === "daysOfWeek") {
          this.form[k] = [];
          return;
        }

        ["allDay", "isRecurring"].includes(k)
          ? (this.form[k] = false)
          : (this.form[k] = null);
      });
      Object.keys(this.selected).map((k) =>
        k === "daysOfWeek" ? (this.selected[k] = []) : (this.selected[k] = null)
      );
      this.editing = false;
    },
    hourFormat(date) {
      return date ? moment(date).format("HH:mm") : null;
    },
    dateFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD") : null;
    },
    datetimeFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;
    },
  },
  computed: {
    ...mapGetters({
      agendas: getters.AGENDAS_GETTER,
      domainLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    hasRecurringHour() {
      return (
        this.form.isRecurring && (!!this.form.startTime || !!this.form.endTime)
      );
    },
  },
  watch: {
    "form.isRecurring": function (isRecurring) {
      if (isRecurring) {
        this.form.start = null;
        this.form.end = null;
        this.form.allDay = null;
        this.form.groupId = this.form.groupId || uuidv4();
        this.form = {
          ...this.form,
          startRecur: this.selected.start,
          endRecur: this.selected.end,
          ...pick(this.selected, ["startTime", "endTime", "daysOfWeek"]),
        };
      } else {
        this.form.start = this.selected.start;
        this.form.end = this.selected.end;
        this.form.allDay = false;
        this.form.startRecur = null;
        this.form.endRecur = null;
        this.form.startTime = null;
        this.form.endTime = null;
        this.form.groupId = null;
        this.form.daysOfWeek = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
v-deep #calendar {
  .fc td.fc-daygrid-day {
    height: 15px !important;
  }
}
</style>
